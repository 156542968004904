import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogPage = ({ data }) => (
  <Layout>
    <SEO
      title="Blog"
      keywords={[
        `Logopediepraktijk`,
        `Mireille Peters`,
        `Zeewolde`,
        `Harderwijk`,
        `Ermelo`,
      ]}
    />
    <div className="blog">
      <h2 className="blog__header">Blog</h2>
      <div className="container">
        <div className="row">
          {data.blog.edges.map(blogpost => (
            <div key={blogpost.node.id} className="col-md-6 col-sm-12 ">
              <div className="card">
                <div className="card-header">{blogpost.node.titel}</div>
                {blogpost.node.afbeelding ? (
                  <Img
                    className="card-img-top"
                    fluid={blogpost.node.afbeelding.fluid}
                    alt="Blog foto"
                  />
                ) : (
                  undefined
                )}
                <div className="card-body">
                  <p className="card-text treatment__text">
                    {blogpost.node.omschrijving.omschrijving}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    blog: allContentfulBlog(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          titel
          omschrijving {
            omschrijving
          }
          afbeelding {
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
export default BlogPage
